//Сетка
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1260px,
    xxl: 1590px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
    xxl: 1560px,
);

@import '../../../node_modules/bootstrap/scss/bootstrap-grid.scss';

:root {
    //Шрифты
    --f-primary: 'Rubik-Regular', Arial, sans-serif;
    --f-primary-medium: 'Rubik-Medium', Arial, sans-serif;
    --f-primary-light: 'Rubik-Light', Arial, sans-serif;
    --f-primary-bold: 'Rubik-Bold', Arial, sans-serif;
    --f-s-title: 32px;
    --f-s-h2: 28px;
    --f-s-h3: 24px;
    --f-s-h4: 20px;
    --f-s-body: 16px;
    --f-s-small: 14px;
    @include media-breakpoint-up(md) {
        --f-s-title: 50px;
        --f-s-h2: 32px;
        --f-s-h3: 28px;
        --f-s-h4: 22px;
    }

    //Цвета
    --c-primary: #5a4eb4;
    --c-primary-hover: #4d439c;
    --c-secondary: #4cc9f0;
    --c-text: #2f3640;
    --c-text-light: #97a1b0;
    --c-lines: rgb(90, 78, 180);
    --c-bg: #f1f6fb;
    --c-white: #fff;
    --c-black: #000;

    //Дополнительно
    --border: 1px solid var(--c-lines);
    --border-radius: 30px;
    --border-radius-btn: 60px;
    --transitions: all 0.4s ease;
}
