.main-menu {
    &__list {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 16px;
        justify-content: center;
    }

    &__item-link {
        text-align: center;
        color: var(--c-text-light);
    }
}
