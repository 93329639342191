.cookie {
    padding: 35px 15px;
    background-color: white;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 101;
    box-shadow: 0px 0px 4px rgba(90,78,180,.5);
    display: none;

    &__show {
        display: block;
    }

    &__content {
        display: flex;
        padding: 0;

        @media (max-width: 991.98px) {
            flex-direction: column;
        }
    }

    &__text {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 125%;

        @media (max-width: 991.98px) {
            margin-bottom: 7px;
        }
    }

    &__btn {
        height: 100%;
        border: 2px solid #473D8F;
        box-sizing: border-box;
        border-radius: 60px;
        padding: 11px 38px;
        color: #473D8F;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        margin-left: 60px;

        @media (max-width: 991.98px) {
            min-width: 260px;
        }

        @media (max-width: 1259.98px) {
            margin: auto;
        }

        &:hover {
            color: var(--c-primary-hover);
            background-color: var(--c-bg);
        }
    }
}
