@mixin font-face($f-name, $f-weight: normal, $f-style: normal) {
    @font-face {
        font-family: '#{$f-name}';
        src: url('../../assets/fonts/#{$f-name}.woff2') format('woff2'),
            url('../../assets/fonts/#{$f-name}.woff') format('woff');
        font-weight: $f-weight;
        font-style: $f-style;
        font-display: swap;
    }
}

@mixin truncate-lines($n) {
    display: -webkit-box;
    -webkit-line-clamp: $n;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
