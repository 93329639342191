.title {
    margin-bottom: 12px;
    font-family: var(--f-primary-medium);
    font-size: var(--f-s-title);
    line-height: 1.1;
    &._custom-margin {
        margin-bottom: 28px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 60px;
        }
    }
}

.sub-title {
    font-family: var(--f-primary-medium);
    font-size: var(--f-s-h2);
    margin-bottom: 16px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 28px;
    }
}

.text-center {
    text-align: center;
}
