@keyframes move {
    0% {
        transform: rotate(var(--r, 0));
    }
    50% {
        transform: rotate(calc(-45deg * var(--m, 1) + var(--r-a, 0deg)));
    }
    100% {
        transform: translate(calc(var(--x, -5px) * var(--d, 1)), calc(var(--y, 1px) * var(--d, 1))) rotate(calc(-45deg * var(--m, 1) + var(--r-a, 0deg)));
    }
}

@keyframes move-back {
    0% {
        transform: translate(calc(var(--x, -5px) * var(--d, 1)), calc(var(--y, 1px) * var(--d, 1))) rotate(calc(-45deg * var(--m, 1) + calc(var(--r-a, 0deg))));
    }
    50% {
        transform: translate(calc(var(--x, -5px) * var(--d, 1) * 0.7), calc(var(--y, 1px) * var(--d, 1) * 0.7))  rotate(var(--r, 0));
    }
    100% {
        transform: rotate(var(--r, 0));
    }
}

@keyframes mobileMenu {
    from {
        right: -100%;
    }

    to {
        right: 0;
    }
}

@keyframes stickyHeader {
    from {
        top: -100%;
    }

    to {
        top: 0;
    }
}

@keyframes scrollToTop {
    from {
        bottom: -100%;
    }

    to {
        bottom: 6%;
    }
}

@keyframes overlay {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
