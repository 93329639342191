.link {
    font-family: var(--f-primary-medium);
    color: var(--c-text);
    transition: var(--transitions);

    &:hover {
        color: var(--c-primary-hover);
    }

    &:active {
        color: var(--c-primary-hover);
        opacity: 0.6;
    }

    &._with-icon {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        .icon {
            width: 22px;
            height: 22px;
            margin-right: 8px;
            fill: var(--c-text);
        }

        &:hover .icon {
            fill: var(--c-primary-hover);
        }
    }

    &._revert {
        color: var(--c-primary);

        &:hover {
            color: var(--c-primary-hover);
        }

        &:hover .icon {
            fill: var(--c-primary-hover);
        }

        &:active {
            color: var(--c-primary-hover);
            opacity: 0.6;
        }

        .icon {
            fill: var(--c-primary-hover);
        }
    }

    &._underline {
        --arrowTopOffset: -3px;
        --linkLineColor: var(--c-primary);
        position: relative;
        text-decoration: none;
        color: var(--linkLineColor);

        span {
            background: linear-gradient(0deg, var(--linkLineColor) 0%, var(--linkLineColor) 100%) no-repeat 100% 100%;
            background-size: var(--background-size, 100%) 1px;
            transition: background-size 0.2s linear var(--background-delay, 0.15s);
            transform: translateZ(0);
        }


        svg {
            position: relative;
            top: var(--arrowTopOffset);
            left: -12px;
            display: inline;
            width: 20px;
            height: 32px;
            vertical-align: top;
            line-height: 1;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1px;
            stroke: var(--linkLineColor);
            stroke-dasharray: 7.95 30;
            stroke-dashoffset: var(--stroke-dashoffset, 46);
            transition: stroke-dashoffset var(--stroke-duration, 0.15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
        }

        &:hover {
            --background-size: 0%;
            --background-delay: 0s;
            --stroke-dashoffset: 26;
            --stroke-duration: 0.3s;
            --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
            --stroke-delay: 0.2s;
        }
    }
}
