.features {
    background-color: var(--c-bg);
    &._no-bg {
        background-color: transparent;
        &::after {
            @include media-breakpoint-up(md) {
                bottom: 0;
            }
        }
    }

    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 28px;
        align-items: center;
        @include media-breakpoint-up(lg) {
            grid-template-columns: 0.7fr 1fr;
            grid-gap: 10%;
        }
    }

    &._revert .container {
        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 0.7fr;
        }
    }

    &__img {
        width: 200px;
        @include media-breakpoint-up(lg) {
            width: auto;
            object-fit: contain;
        }
    }

    &._revert &__img {
        order: -1;
        @include media-breakpoint-up(lg) {
            order: 0;
        }
    }

    &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 28px;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
}
