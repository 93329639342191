.steps {
    background-color: var(--c-bg);
    &._no-bg {
        background-color: transparent;
        &::before {
            @include media-breakpoint-up(md) {
                top: 10%;
            }
        }
    }

    &__inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 28px;
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px;
        }
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            width: 100%;
            height: 1px;
            border-bottom: 1px var(--c-secondary) dashed;
        }
    }

    &__item-inner {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 16px;
        padding: 28px 16px;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: -9px -9px 16px #FAFBFF, 9px 9px 20px rgba(90, 78, 180, 0.5);
        @include media-breakpoint-up(sm) {
            grid-gap: 20px;
            padding: 28px 20px;
        }
        @include media-breakpoint-up(xl) {
            padding: 52px 36px;
        }
    }

    &__item-icon-inner {
        display: grid;
        place-items: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px rgba(90, 78, 180, 0.5);
    }

    &__item-icon {
        width: 30px;
        height: 30px;
    }

    &__item-step {
        position: relative;
        z-index: 2;
        display: block;
        width: fit-content;
        padding: 0 12px;
        margin-bottom: 12px;
        margin-left: auto;
        text-align: right;
        color: var(--c-secondary);
        background-color: var(--c-bg);
        opacity: 0.9;
    }

    &._no-bg &__item-step {
        background-color: var(--c-white);
    }

    &__special-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 44px 20px;
        @include media-breakpoint-up(xl) {
            padding: 80px 20px 44px 40px;
        }
    }

    &__special-item-text {
        margin-bottom: 16px;
    }
}
