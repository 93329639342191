.bubbles {
    position: relative;
    &._top::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: -40vw;
        width: 80vw;
        height: 80vw;
        background: radial-gradient(50% 50% at 50% 50%, #4CC9F0 0%, rgba(76, 201, 240, 0.991353) 6.67%, rgba(76, 201, 240, 0.96449) 13.33%, rgba(76, 201, 240, 0.91834) 20%, rgba(76, 201, 240, 0.852589) 26.67%, rgba(76, 201, 240, 0.768225) 33.33%, rgba(76, 201, 240, 0.668116) 40%, rgba(76, 201, 240, 0.557309) 46.67%, rgba(76, 201, 240, 0.442691) 53.33%, rgba(76, 201, 240, 0.331884) 60%, rgba(76, 201, 240, 0.231775) 66.67%, rgba(76, 201, 240, 0.147411) 73.33%, rgba(76, 201, 240, 0.0816599) 80%, rgba(76, 201, 240, 0.03551) 86.67%, rgba(76, 201, 240, 0.0086472) 93.33%, rgba(76, 201, 240, 0) 100%);
        transform: rotate(-60deg);
        opacity: 0.8;
        @include media-breakpoint-up(md) {
            top: -2%;
            left: -24vw;
            width: 36vw;
            height: 36vw;
        }
    }

    &._bottom::after {
        display: none;
        @include media-breakpoint-up(md) {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: 0;
            right: -62%;
            display: block;
            width: 80vw;
            height: 80vw;
            background: radial-gradient(50% 50% at 50% 50%, #4CC9F0 0%, rgba(76, 201, 240, 0.991353) 6.67%, rgba(76, 201, 240, 0.96449) 13.33%, rgba(76, 201, 240, 0.91834) 20%, rgba(76, 201, 240, 0.852589) 26.67%, rgba(76, 201, 240, 0.768225) 33.33%, rgba(76, 201, 240, 0.668116) 40%, rgba(76, 201, 240, 0.557309) 46.67%, rgba(76, 201, 240, 0.442691) 53.33%, rgba(76, 201, 240, 0.331884) 60%, rgba(76, 201, 240, 0.231775) 66.67%, rgba(76, 201, 240, 0.147411) 73.33%, rgba(76, 201, 240, 0.0816599) 80%, rgba(76, 201, 240, 0.03551) 86.67%, rgba(76, 201, 240, 0.0086472) 93.33%, rgba(76, 201, 240, 0) 100%);
            transform: rotate(-60deg);
            opacity: 0.8;
        }
    }
}
