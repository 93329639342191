.modal {
    position: fixed;
    z-index: -5000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(52, 59, 76, 0.95);
    height: 100%;
    width: 100%;
    opacity: 0;

    &-active {
        z-index: 5000;
        opacity: 1;
        transition: opacity .15s;
    }

    &__inner {
        position: relative;
        min-width: 400px;
        background-color: #fff;
        padding: 20px 25px 25px;
    }

    &__header {
        margin: 0;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 20px;
    }

    &__btn-close {
        position: absolute;
        top: 25px;
        right: 25px;
        line-height: 0;

        &:hover {
            color: var(--c-primary);
        }
    }

    &__msg-info {
        display: none;
        font-size: 15px;

        &._show {
            display: block;
        }
    }
}
