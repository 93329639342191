.mobile-btn {
    --color: var(--c-primary);
    position: relative;
    width: 36px;
    height: 36px;
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    cursor: pointer;

    @media (min-width: 1259.98px) {
        display: none;
    }

    .icon {
        position: absolute;
        top: -6px;
        left: -14px;
        display: block;
        width: 64px;
        height: 48px;
        stroke: var(--color);
        stroke-width: 4px;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;

        path {
            transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
            stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
            stroke-dashoffset: var(--offset, 126px);
            transform: translateZ(0);

            &:nth-child(2) {
                --duration: 0.7s;
                --easing: ease-in;
                --offset: 100px;
                --array-2: 74px;
            }

            &:nth-child(3) {
                --offset: 133px;
                --array-2: 107px;
            }
        }
    }

    &.active {
        .icon path {
            --offset: 57px;

            &:nth-child(1),
            &:nth-child(3) {
                --delay: 0.15s;
                --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
            }

            &:nth-child(2) {
                --duration: 0.4s;
                --offset: 2px;
                --array-1: 1px;
            }

            &:nth-child(3) {
                --offset: 58px;
            }
        }
    }
}
