.ability {
    color: var(--c-white);
    background: linear-gradient(293.17deg, #27263E -1.45%, #27263F 5.2%, #292841 11.84%, #2B2A45 18.48%, #2E2E4B 25.13%, #323353 31.77%, #37385C 38.42%, #3D3E66 45.06%, #424471 51.7%, #484A7B 58.35%, #4D4F84 64.99%, #51548C 71.64%, #545892 78.28%, #565A96 84.92%, #585C98 91.57%, #585C99 98.21%);

    &__inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px;
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 20px;
        align-items: center;
    }

    &__item-img {
        width: 60px;
        height: 60px;
    }
}
