.header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 120;
    background-color: var(--c-white);
    transition: var(--transitions);
    .container {
        display: grid;
        grid-template-columns: 1fr auto;
        padding-top: 20px;
        padding-bottom: 20px;

        @media (min-width: 1259.98px) {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
        }
    }

    &__inner {
        display: none;

        @media (min-width: 1259.98px) {
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-gap: 20px;
        }
    }

    .main-menu {
        display: none;

        @media (min-width: 1259.98px) {
            display: initial;
        }
    }
}
