.contacts {
    overflow: hidden;
    &._top::before {
        display: none;
        @include media-breakpoint-up(md) {
            top: 0;
            left: -14%;
            display: block;
            width: 26vw;
            height: 26vw;
        }
    }
    &__sub-title {
        margin-bottom: 20px;
        font-size: var(--f-s-h4);
        text-align: center;
    }

    &__link-inner {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 8px;
        align-items: center;
        width: fit-content;
        margin: 0 auto 12px;
        font-family: var(--f-primary-medium);
        font-size: var(--f-s-h4);
        @include media-breakpoint-up(sm) {
            font-size: var(--f-s-h2);
        }
    }
}
