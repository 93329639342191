.logo {
    display: block;
    max-width: 203px;
    transition: var(--transitions);
    &:hover {
        opacity: 0.6;
    }
    & img {
        width: 100%;
    }
}
