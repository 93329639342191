.sd-form {
    .input {
        &__wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &__error {
            border-color: #CB6969;
        }
    }

    .msg {
        &-error {
            display: none;
            color: #CB6969;
            font-size: 10px;
            position: absolute;
            bottom: 0;

            &._show {
                display: block;
            }

            &._response {
                font-size: 14px;
                position: absolute;
                bottom: 12px;
            }
        }
    }

    input[type=submit] {
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    label {
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: normal;
        line-height: 15px;
        color: #6a6a6a;

        &.required-star {
            &::after {
                content: "*";
                color: red;
            }
        }
    }

    input, textarea {
        padding: 8px 12px;
        font-size: 15px;
        border: 1px solid #DEDDE7;
        border-radius: 6px;
        transition: all 0.2s ease;
        margin-bottom: 14px;

        &::placeholder {
            color: #AAA7C4;
        }

        &:focus {
            border-color: #6258AA;
        }

        &:focus-visible {
            outline: none;
        }
    }
}
