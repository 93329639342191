html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    height: 100%;
}

.hidden {
    display: none !important;
}

#svg-icons {
    display: none;
}

.margin-block {
    margin-bottom: 60px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 120px;
    }
}

.margin-block-small {
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }
}

.block-inside {
    padding: 80px 0 80px;
    @include media-breakpoint-up(lg) {
        padding: 100px 0 120px;
    }
}

.block-inside-small {
    padding: 60px 0;
    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }

    &_without-padding-top {
        padding: 0 0 60px;

        @include media-breakpoint-up(lg) {
            padding: 0 0 80px;
        }
    }
}

.block-inside-small.margin-block:not(._mobile-margin),.block-inside.margin-block:not(._mobile-margin)  {
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }
}

.clickable-link {
    color: var(--c-primary);

    &:hover {
        color: var(--c-primary-hover);
    }
}

.base-link {
    color: var(--c-primary);
    word-break: break-word;

    &:hover {
        color: var(--c-primary-hover);
        text-decoration: underline;
    }
}