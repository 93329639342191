.promo-block {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: 28px;
        @include media-breakpoint-up(lg) {
            grid-template-columns: 0.8fr 1fr;
            grid-gap: 40px;
        }
    }

    &__inner {
        margin-top: 80px;
    }

    &__text {
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }
    }

    &__link {
        max-width: 260px;
        @include media-breakpoint-down(lg) {
            margin: auto;
        }
    }

    &__img {
        order: -1;
        width: 200px;
        @include media-breakpoint-up(lg) {
            order: 0;
            justify-self: flex-end;
            width: auto;
            object-fit: contain;
        }
    }

    &__achives {
        &-up {
            display: flex;
            margin-top: 60px;

            @media (max-width: 991.98px) {
                justify-content: center;
            }

            &-item {
                display: flex;
                align-items: center;
                font-size: 14px;

                .first-item {
                    &__text {
                        margin-left: 6px;
                    }
                }

                .second-item {
                    &__text {
                        margin-left: 5px;
                    }
                }

                &:nth-child(2) {
                    margin-left: 40px;
                }

                @media (min-width: 991.98px) and (max-width: 1259.98px) {
                    &:nth-child(2) {
                        margin-left: 1px;
                    }
                }
            }
        }

        &-down {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            @media (max-width: 991.98px) {
                align-items: center;
            }

            &-item {
                font-weight: bold;
                font-size: 17px;
            }
        }
    }
}
