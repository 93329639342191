.btn-up {
    display: none;
    &._active {
        position: fixed;
        z-index: 100;
        bottom: 6%;
        right: 4%;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        animation: scrollToTop 0.6s ease;
        @include media-breakpoint-up(lg) {
            width: 60px;
            height: 60px;
        }

    }

    &__icon {
        width: 100%;
        height: 100%;
        fill: var(--c-primary);
    }
}
