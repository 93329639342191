.reg-block {
    padding-top: 119px;

    @include media-breakpoint-between(md, xl) {
        margin-bottom: 0 !important;
    }
    @include media-breakpoint-up(lg) {
        color: var(--c-white);
    }
    .container {
        @include media-breakpoint-up(lg) {
            padding-top: 100px;
            padding-bottom: 100px;
            background: url('../../assets/img/index/reg-block-bg.webp') no-repeat center;
            background-size: contain;
        }
        @include media-breakpoint-up(xl) {
            padding-top: 8%;
            padding-bottom: 8%;
        }
    }

    .sub-title {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        @include media-breakpoint-up(lg) {
            max-width: 800px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 990px;
        }
    }

    &__btn-reg {
        display: block;
        max-width: fit-content;
        margin: 0 auto;
    }
}
