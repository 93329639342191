.mobile-side {
    --topMenuHeight: 84px;
    position: fixed;
    z-index: 120;
    top: var(--topMenuHeight);
    right: 0;
    width: fit-content;
    padding: 32px 20px 40px;
    background-color: var(--c-white);
    overflow: auto;
    animation: mobileMenu 0.4s ease;

    @media (min-width: 1259.98px) {
        display: none;
    }

    .container {
        overflow-y: auto;
    }

    .main-menu {
        &__list {
            grid-auto-flow: dense;
            justify-content: flex-start;
        }

        &__item-link {
            font-size: var(--f-s-body);
            color: var(--c-text);
        }
    }
}
