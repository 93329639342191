.main {
    overflow-x: clip;
    flex: 1 0 auto;
    padding-top: 160px;

    @include media-breakpoint-down(lg) {
        padding-top: 120px;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
