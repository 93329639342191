.body {
    max-width: 100vw;
    font-family: var(--f-primary);
    font-size: var(--f-s-body);
    line-height: 1.5;
    color: var(--c-text);
    overflow: hidden;
    overflow-y: auto;
    &._no-scroll {
        overflow: hidden;
    }
}
