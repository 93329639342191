.news {
    padding-top: 90px;

    &__block {
        background: #ffffff;
        box-shadow: -9px -9px 16px #fafbff, 9px 9px 20px rgba(90, 78, 180, 0.5);
        border-radius: 15px;
        margin-bottom: 45px;
    }

    &__content {
        @media (min-width: 1260px) {
            display: flex;
            flex-wrap: wrap;
        }
        padding: 70px 60px;
    }

    &__text {
        line-height: 68%;

        @media (min-width: 1260px) {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        @include media-breakpoint-up(xl) {
            width: 85%;
        }

        @media (min-width: 1260px) and (max-width: 1590px) {
            width: 81%;
        }

        &-date {
            font-size: 14px;
            color: #97a1b0;
            line-height: 110%;
            font-weight: normal;
            margin-bottom: 10px;
        }

        &-title {
            font-size: 22px;
            line-height: 130%;
            margin-bottom: 15px;
        }

        &-content {
            font-family: var(--f-primary-light);
            font-size: 16px;
            line-height: 145%;

            @media (max-width: 1259.98px), (min-width: 1590px) {
                br {
                    display: none;
                }
            }
        }

        &.without-img-block {
            width: 100%;
            margin-right: 0;
        }
    }

    &__img {
        &-block {
            padding-top: 13px;

            @include media-breakpoint-down(xl) {
                display: flex;
                justify-content: space-around;
            }

            @include media-breakpoint-only(xs) {
                display: flex;
                flex-direction: column;
            }

            img {
                width: 170px;
            }

            @media (min-width: 1260px) {
                margin: auto;
            }
        }

        &-first {
            margin-bottom: 30px;
        }

        &-second {
            height: 54px;

            @include media-breakpoint-only(xs) {
                margin-bottom: 30px;
            }
        }
    }

    &__picture {
        width: 49%;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 10px;
        }

        & img {
            width: 100%;
            margin-bottom: 0;
        }
    }

    &__links {
        &-list {
            width: fit-content;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: underline;
            color: var(--c-primary);
        }

        &-item {
            display: block;
            margin-bottom: 10px;

            &:before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                background-image: url('../../assets/img/icons/arrow.webp');
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 10px;
                width: 15px;
                height: 15px;
                margin-top: 2px;
            }

            &:hover {
                color: var(--c-primary-hover);
            }
        }
    }

    &__btn-all-news {
        display: block;
        max-width: fit-content;
        margin: 0 auto;
    }

    &-page {
        padding-top: 30px;
        max-height: calc(100% - 500px);

        &__nav {
            display: flex;
            padding-left: 65px;
            line-height: 12px;
            font-size: 16px;
            font-weight: 700;

            &__link {
                color: var(--c-text-light);

                &:hover {
                    color: var(--c-text);
                }
            }

            &__separator {
                margin: 0 15px;
            }

            &__current-page {
                cursor: pointer;
            }
        }

        &__text {
            @media (min-width: 1260px) and (max-width: 1590px) {
                width: auto;
            }

            @media (min-width: 1260px) {
                margin-right: 40px;
                margin-bottom: 30px;
                width: 70%;
            }

            &-title {
                @media (max-width: 1259.98px), (min-width: 1590px) {
                    br {
                        display: none;
                    }
                }
            }
        }

        &__img {
            &-block {
                padding-top: 111px;

                @media (min-width: 1590px) {
                    padding-top: 50px;
                }

                @media (min-width: 1260px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                @include media-breakpoint-down(xl) {
                    display: flex;
                    justify-content: space-around;
                    padding-top: 0;
                    margin-top: 30px;
                }

                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: column;
                }

                img {
                    width: 260px;
                }

                &-two {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 41px;
                }

                &_mobile {
                    &-column {
                        @include media-breakpoint-down(md) {
                            flex-direction: column;
                            margin-bottom: 0;

                            img,
                            picture {
                                width: 100% !important;
                            }
                        }
                    }

                    &-row {
                        @include media-breakpoint-down(md) {
                            flex-direction: row;
                            margin-bottom: 0;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                &-cbonds-second {
                    padding: 0;

                    @media (min-width: 1590px) {
                        padding-top: 0;
                    }
                }
            }

            &-first {
                margin-bottom: 30px;
            }

            &-second {
                @media (max-width: 1259.98px) {
                    height: 100%;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}
