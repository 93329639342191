.btn {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 55px;
    padding: 18px 32px;
    border-radius: var(--border-radius-btn);
    font-family: var(--f-primary-medium);
    line-height: 1;
    text-align: center;
    color: var(--c-white);
    background-color: var(--c-primary);
    box-shadow: -9px -9px 16px #FAFBFF, 9px 9px 20px rgba(90, 78, 180, 0.5);
    transition: var(--transitions);
    &:hover {
        background-color: var(--c-primary-hover);
    }

    &._small {
        min-width: 120px;
        height: 40px;
    }

    &._revert {
        color: var(--c-primary);
        background-color: var(--c-white);
        box-shadow: inset -4.347e-16px -7.0992px 7.0992px rgba(82, 112, 166, 0.5), inset 4.347e-16px 7.0992px 7.0992px #FFFFFF;
        &:hover {
            color: var(--c-primary-hover);
            background-color: var(--c-bg);
            box-shadow: inset -4.347e-16px -7.0992px 7.0992px rgba(82, 112, 166, 0.5), inset 4.347e-16px 7.0992px 7.0992px #FFFFFF;
        }
    }
}
