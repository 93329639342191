.comparison-block {
    //background: #FFFFFF;
    padding: 90px 0;

    &__title {
        margin-bottom: 45px;
    }

    .comparison-table {
        box-shadow: -9px -9px 16px #FAFBFF, 9px 9px 20px rgba(90, 78, 180, 0.5);
        border-radius: 15px;
        border-collapse: collapse;
        overflow:hidden;

        &__container {
            width: 100%;

            @media (max-width: 767.98px) {
                overflow: scroll;
                box-shadow: -9px -9px 16px #FAFBFF, 9px 9px 20px rgba(90, 78, 180, 0.5);
                border-radius: 15px;
                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                @media (max-width: 767.98px) {
                    display: none;
                }
            }
        }

        &__header {
            background: linear-gradient(293.17deg, #27263E -1.45%, #27263F 5.2%, #292841 11.84%, #2B2A45 18.48%, #2E2E4B 25.13%, #323353 31.77%, #37385C 38.42%, #3D3E66 45.06%, #424471 51.7%, #484A7B 58.35%, #4D4F84 64.99%, #51548C 71.64%, #545892 78.28%, #565A96 84.92%, #585C98 91.57%, #585C99 98.21%);
            color: #FFFFFF;
            height: 90px;

            &-item {
                text-align: center;
                font-weight: bold;
                font-size: 22px;
                width: 25%;
                padding: 0 10px;
                line-height: 100%;
            }
        }

        &__row {
            height: 60px;
            font-size: 16px;
            color: var(--c-text);
            border-bottom: 1px solid rgba(76, 201, 240, 0.3);
            line-height: 100%;
            background-color: #FFFFFF;

            &-title {
                padding-left: 40px;
                font-weight: bold;

            }

            &-item {
                text-align: center;

                &:last-child {
                    background-color: var(--c-bg);
                }
            }

            &:last-child {
                height: 76px;
                border-bottom: none;
            }
        }
    }
}
