.feedback {
    &-btn {
        position: fixed;
        z-index: 100;
        bottom: 0;
        right: 30px;
        height: auto;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 12px 12px 0 0;
        box-shadow: -5px -1px 25px rgba(90,78,180,.5);
    }
}
