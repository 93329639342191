.footer {
    flex: 0 0 auto;
    padding: 30px 0;
    font-size: var(--f-s-small);
    color: var(--c-text-light);
    background: linear-gradient(293.17deg, #27263E -1.45%, #27263F 5.2%, #292841 11.84%, #2B2A45 18.48%, #2E2E4B 25.13%, #323353 31.77%, #37385C 38.42%, #3D3E66 45.06%, #424471 51.7%, #484A7B 58.35%, #4D4F84 64.99%, #51548C 71.64%, #545892 78.28%, #565A96 84.92%, #585C98 91.57%, #585C99 98.21%);
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        @include media-breakpoint-up(lg) {
            grid-template-columns: 0.5fr 0.5fr 0.5fr;
            grid-gap: 40px;
        }
    }

    .logo {
        margin-bottom: 20px;
        img {
            width: 130px;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__link {
        align-self: flex-start;
        transition: var(--transitions);
        &:hover {
            text-decoration: underline;
        }
    }

    &__sub-links {
        &-toggle-button {
            align-self: flex-start;
            cursor: pointer;
            display: flex;
            align-items: center;

            &-chevron {
                display: inline-block;
                width: 6.5px;
                height: 6.5px;
                margin-top: 2px;
                margin-left: 6px;
                border-left: 2px solid #8b97ae !important;
                border-top: 2px solid #8b97ae !important;
                transform: rotate(135deg);
                transition: all .2s ease;
                
                &_down {
                    transform: rotate(225deg);
                    margin-top: 0;
                }
            }
        }

        &-wrapper {
            // По дефолту линки не отображаются
            height: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            margin-top: 5px;
            transition: all .2s ease;

            &_show {
                height: 100%;
            }
        }
    }
}
