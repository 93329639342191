.advantages-block {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: var(--c-bg);
    }
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 28px;
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px;
        }
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 20px;
        align-items: center;
        padding: 28px 20px;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
        box-shadow: -9px -9px 16px #FAFBFF, 9px 9px 20px rgba(90, 78, 180, 0.5);
        @include media-breakpoint-up(xl) {
            padding: 52px 36px;
        }
    }

    &__item-name {
        font-family: var(--f-primary-medium);
    }

    &__item-icon-inner {
        display: grid;
        place-items: center;
        width: 60px;
        height: 60px;
        padding: 4px;
        border-radius: 50%;
        box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px rgba(90, 78, 180, 0.5);
    }

    &__item-text {
        grid-column: 1/-1;
    }
}
